import React from "react"
import SEO from '../../components/seo'

import Osirase from '../../layouts/osirase'
import Kikinayo from '../../components/kikinayo'

export default () => (


  <Osirase>

  	<SEO title="登録完了"/>

	<h1>登録完了</h1>

	<p>すぐに確認のメールが届きますので、<br/>
	<strong>迷惑メールフォルダも含めて、受信をチェックしてください</strong>。</p>

	<p>届いていなければ、アドレスの入力ミスが考えられます。再度お試しください。</p>

	<Kikinayo/>

  </Osirase>
)
