import React from "react"

import Soundcloud from '../components/soundcloud'



export default () => (
<>

	<h2 id='kikinayo'>それはそうと 曲を聞きなよ</h2>

	<Soundcloud/>


</>
)
